/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import Box from "@mui/joy/Box";
import TwoSidedLayout from "../../components/TwosidedLayout";

export default function Slice03() {
  return (
    <TwoSidedLayout>
      <Box>Slice3</Box>
    </TwoSidedLayout>
  );
}
