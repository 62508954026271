import React from "react";
import "./index.css";
import { CssVarsProvider } from "@mui/joy/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/joy/CssBaseline";
import Slice01 from "../../blocks/Slice01";
import Slice02 from "../../blocks/Slice02";
import Slice03 from "../../blocks/Slice03";
import Slice04 from "../../blocks/Slice04";
import Slice05 from "../../blocks/Slice05";

function App() {
  return (
    <CssVarsProvider>
      <main>
        <CssBaseline />
        <Box
          sx={{
            height: "100vh",
            overflowY: "scroll",
            scrollSnapType: "y mandatory",
            "& > div": {
              scrollSnapAlign: "start",
            },
          }}
        >
          <Slice01 />
          <Slice02 />
          <Slice03 />
          <Slice04 />
          <Slice05 />
        </Box>
      </main>
    </CssVarsProvider>
  );
}

export default App;
