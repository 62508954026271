/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import Box from "@mui/joy/Box";
import { useColorScheme } from "@mui/joy/styles";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import Input from "@mui/joy/Input";
import Button from "@mui/joy/Button";
import ShareIcon from "@mui/icons-material/Share";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import "./index.css";

export default function Slice01() {
  const { mode, setMode } = useColorScheme();
  React.useEffect(() => {
    console.log("mode", mode);
    setMode("dark");
  });

  return (
    <Box
      sx={[
        (theme) => ({
          minHeight: "100vh",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "flex-start",
          margin: 0,
          flexDirection: "column",
          backgroundImage: "url(/bg.png)",
          backgroundSize: "cover",
          backgroundPosition: "left",
          backgroundRepeat: "no-repeat",
        }),
      ]}
    >
      <Box
        id="TitleLine"
        sx={{
          height: "100px",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          id="Left"
          sx={{ display: "flex", marginTop: "12px", marginLeft: "23px" }}
        >
          <Box id="Logo">
            <img className="logoImage" src="logo_dark.png" alt="logo" />
          </Box>
          <Box
            id="Links"
            sx={{
              height: "50px",
              display: { xs: "none", lg: "flex" },
              marginLeft: "61px",
            }}
          >
            <Link
              sx={{
                color: "white",
                marginTop: "25px",
                marginRight: "25px",
              }}
              underline="none"
            >
              Who we Serve
            </Link>
            <Link
              sx={{
                color: "white",
                marginTop: "25px",
                marginRight: "25px",
              }}
              underline="none"
            >
              Experts
            </Link>
            <Link
              sx={{
                color: "white",
                marginTop: "25px",
                marginRight: "25px",
              }}
              underline="none"
            >
              Resources
            </Link>
            <Link
              sx={{
                color: "white",
                marginTop: "25px",
                marginRight: "25px",
              }}
              underline="none"
            >
              Pricing
            </Link>
            <Link
              sx={{
                color: "white",
                marginTop: "25px",
                marginRight: "25px",
              }}
              underline="none"
            >
              Contact
            </Link>
          </Box>
        </Box>
        <Box id="Right" sx={{ display: "flex" }}>
          <Button
            sx={{
              height: "30px",
              color: "white",
              borderRadius: "20px",
              border: "1px solid white",
              marginTop: "30px",
              backgroundColor: "#202121",
              transition: "background-color 0.3s ease", // 添加过渡效果
              "&:hover": {
                backgroundColor: "gray", // 悬停时背景色变为浅绿色
              },
            }}
            underline="none"
            variant="outlined"
            startDecorator={<ShareIcon />}
          >
            Share
          </Button>
          <Link
            sx={{
              height: "30px",
              color: "white",
              marginTop: "30px",
              marginLeft: "15px",
              marginRight: "5px",
            }}
            underline="none"
          >
            Log In
          </Link>
          <Box
            sx={{
              height: "30px",
              color: "white",
              marginTop: "30px",
            }}
          >
            |
          </Box>
          <Link
            sx={{
              height: "30px",
              color: "white",
              marginTop: "30px",
              marginLeft: "5px",
              marginRight: "23px",
            }}
            underline="none"
          >
            Become an Expert
          </Link>
        </Box>
      </Box>
      <Box
        id="secondLine"
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center", // 水平居中
          marginTop: "80px",
          marginBottom: "15px",
        }}
      >
        <Typography
          sx={{
            color: "white",
            fontSize: "40px",
            fontWeight: 400,
            fontFamily: "Jaldi",
          }}
        >
          Redefine What's possible
        </Typography>
      </Box>
      <Box
        id="thirdLine"
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center", // 水平居中
          marginBottom: "15px",
        }}
      >
        <Typography
          sx={{
            color: "white",
            fontSize: "25px",
            fontWeight: 400,
            fontFamily: "Jaldi",
          }}
        >
          We're here to help you create AI devices that make the world go
          'whoa!'
        </Typography>
      </Box>
      <Box
        id="fourthLine"
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center", // 水平居中
        }}
      >
        <Typography
          sx={{
            color: "white",
            fontSize: "18px",
            fontWeight: 400,
            fontFamily: "Jaldi",
            width: "620px",
            textAlign: "center",
          }}
        >
          Unleash the power of imagination and innovation, transform ideas into
          cutting-edge AI-driven devices from idea to global breakthrough.
        </Typography>
      </Box>
      <Box
        id="buttonLine"
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center", // 水平居中
          marginTop: "40px",
        }}
      >
        <Box
          id="ButtonBoxLine"
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center", // 水平居中
          }}
        >
          <Box id="ButtonBox">
            <Link
              sx={{
                height: "65px",
                width: "230px",
                color: "white",
                borderRadius: "10px",
                border: "1px solid #4682DC",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "14px",
                padding: "10px",
                margin: "10px",
                transition: "border-color 0.3s ease", // 添加过渡效果
                "&:hover": {
                  borderColor: "#4EDC46", // 悬停时边框颜色变为绿色
                },
              }}
              underline="none"
            >
              Help me brainstorm on the voice user interface
            </Link>
          </Box>
          <Box id="ButtonBox">
            <Link
              sx={{
                height: "65px",
                width: "230px",
                color: "white",
                borderRadius: "10px",
                border: "1px solid #4682DC",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "14px",
                padding: "10px",
                margin: "10px",
                transition: "border-color 0.3s ease", // 添加过渡效果
                "&:hover": {
                  borderColor: "#4EDC46", // 悬停时边框颜色变为绿色
                },
              }}
              underline="none"
            >
              Talk to an experienced product test engineer
            </Link>
          </Box>
          <Box id="ButtonBox">
            <Link
              sx={{
                height: "65px",
                width: "230px",
                color: "white",
                borderRadius: "10px",
                border: "1px solid #4682DC",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "14px",
                padding: "10px",
                margin: "10px",
                transition: "border-color 0.3s ease", // 添加过渡效果
                "&:hover": {
                  borderColor: "#4EDC46", // 悬停时边框颜色变为绿色
                },
              }}
              underline="none"
            >
              Help me figure out the best power architecture
            </Link>
          </Box>
          <Box id="ButtonBox">
            <Link
              sx={{
                height: "65px",
                width: "230px",
                color: "white",
                borderRadius: "10px",
                border: "1px solid #4682DC",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "14px",
                padding: "10px",
                margin: "10px",
                transition: "border-color 0.3s ease", // 添加过渡效果
                "&:hover": {
                  borderColor: "#4EDC46", // 悬停时边框颜色变为绿色
                },
              }}
              underline="none"
            >
              Develop a comprehensive product requirement
            </Link>
          </Box>
        </Box>
      </Box>
      <Box
        id="inputLine"
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center", // 水平居中
          marginTop: "30px",
        }}
      >
        <Input
          startDecorator={<AttachFileIcon />}
          endDecorator={<SendIcon />}
          placeholder="Message ChatGPT"
          sx={{
            "--Input-gap": "20px",
            "--Input-radius": "35px",
            "--Input-placeholderOpacity": 0.5,
            "--Input-minHeight": "66px",
            "--Input-paddingInline": "40px",
            "--Input-decoratorChildHeight": "30px",
            width: "800px",
            height: "80px",
            border: "1px solid gray",
            transition: "border-color d0.3s ease", // 添加过渡效果
            "&:hover": {
              borderColor: "white", // 悬停时边框颜色变为绿色
            },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "24px",
            backgroundColor: "#2F2F2F",
          }}
        ></Input>
      </Box>
    </Box>
  );
}
